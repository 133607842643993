import React from 'react';

function LinkCard({ link, categoryTitle, categoryColor }) {
  return (
    <div className="relative flex flex-col justify-between border border-gray-200 rounded-lg p-4 m-2 md:mb-4 shadow-md overflow-hidden hover:bg-gray-50 transition-colors duration-300">
      {/* Category Title fixed at the top right corner with dynamic opacity */}
      <span className={`absolute top-2 right-2 text-white text-xs font-semibold px-2 py-1 rounded ${categoryColor} opacity-80 transition-opacity duration-300 group-hover:opacity-100`}>
        {categoryTitle}
      </span>
      <div className="card-top">
        <h3 className="text-lg font-semibold text-gray-800 mt-4">
          <a href={link.url} target="_blank" rel="noopener noreferrer" className="hover:underline">
            {link.title}
          </a>
        </h3>
        <p className="text-sm text-gray-700 mt-2">{link.description}</p>
      </div>
      <div className="card-bottom">
        <div className="text-xs text-gray-500 mt-2">
          {link.author && (
            <span>
              By {link.authorLink ? (
                <a href={link.authorLink} target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:underline">
                  {link.author}
                </a>
              ) : (
                <span className="text-gray-500">{link.author}</span>
              )}
            </span>
          )}
          {link.author && link.date && <span className="mx-2">|</span>}
          {link.date && <span>{link.date}</span>}
        </div>
        <div className="mt-2">
          {link.tags.map((tag, index) => (
            <span key={index} className="inline-block bg-gray-200 text-gray-700 text-xs px-2 py-1 rounded-full mr-2">
              {tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LinkCard;
