import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <h2 className="text-2xl text-gray-600 mb-2">404 Error</h2>
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Page Not Found</h1>
      <p className="text-gray-700 mb-6">Sorry we can't find the page you're looking for.</p>
      <button
        onClick={handleBackHome}
        className="px-6 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
      >
        Back to Home
      </button>
    </div>
  );
}

export default NotFound;
