import React from 'react';

const CategoryFilter = ({ categories, activeCategories, toggleCategory }) => {
  return (
    <div className="flex flex-wrap justify-center mb-6">
      {categories.map(category => (
        <button
          key={category.id}
          onClick={() => toggleCategory(category.id)}
          className={`m-2 px-4 py-2 rounded md:text-md text-sm border transition-all duration-300 ${
            activeCategories.includes(category.id)
              ? 'bg-gray-800 text-white border-gray-800' // Dark gray with border when active
              : 'bg-gray-100 text-gray-600 border-gray-300 hover:bg-gray-200 hover:text-black hover:border-gray-400'
          }`}
          aria-pressed={activeCategories.includes(category.id)} // Accessibility: indicating active state
          aria-label={`Filter by ${category.title}`} // Accessible label for better screen reader support
        >
          {category.title}
        </button>
      ))}
    </div>
  );
};

export default CategoryFilter;
