import React from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

function Header() {
  return (
    <header className="bg-gray-100 text-black py-4" role="banner">
      <div className="container mx-auto flex items-center justify-between px-4">
        {/* Logo/Title */}
        <h1 className="text-xl md:text-2xl font-bold">
          <a href="/" aria-label="AEM-Links Home">
            AEM-Links
          </a>
        </h1>

        {/* Navigation Links */}
        <nav className="flex items-center space-x-4" aria-label="Social Media Links and CTA">
          {/* LinkedIn Icon */}
          <a
            href="https://www.linkedin.com/in/arunpatidar26/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn Profile"
            className="text-black hover:text-white hover:bg-black p-2 rounded transition-colors duration-300"
          >
            <FaLinkedin size={24} />
          </a>

          {/* GitHub Icon */}
          <a
            href="https://github.com/arunpatidar02/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="GitHub Profile"
            className="text-black hover:text-white hover:bg-black p-2 rounded transition-colors duration-300"
          >
            <FaGithub size={24} />
          </a>

          {/* "Link It" CTA */}
          <a
            href="https://github.com/arunpatidar02/aemlinks-data/issues"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link It or Report an Issue"
            className="bg-black text-white font-bold px-4 py-2 rounded hover:bg-gray-300 hover:text-black transition-colors duration-300"
          >
            Link It
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
