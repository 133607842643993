import React, { useState } from 'react';
import { FaCopy, FaCheck } from 'react-icons/fa';

function QuickLinks({ quicklinks }) {
  const [copiedLink, setCopiedLink] = useState(null);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        setCopiedLink(url);
        setTimeout(() => setCopiedLink(null), 2000);
      })
      .catch(err => console.error('Failed to copy!', err));
  };

  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6">
      <h2 className="text-xl font-semibold mb-4">Quick Links</h2>
      <ul>
        {quicklinks.map((ql, index) => (
          <li 
            key={index} 
            className={`flex justify-between items-center p-2 mb-3 relative ${index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'}`}
          >
            <a
              href={ql.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 hover:no-underline relative group"
            >
              {ql.title}
              {/* Tooltip for URL */}
              <span className="hidden lg:inline ms-2 me-2 p-1 w-fit mt-1 text-center text-sm opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                {ql.url}
              </span>
            </a>
            <button
              aria-label="Copy Link"
              className={`flex items-center px-3 py-1 rounded text-sm font-medium focus:outline-none transition-colors duration-300 ${
                copiedLink === ql.url
                  ? 'bg-green-700 text-white'  // Green background and white text when copied
                  : 'bg-gray-300 text-gray-700 hover:bg-gray-900 hover:text-white'
              }`}
              onClick={() => handleCopy(ql.url)}
            >
              {copiedLink === ql.url ? <FaCheck className="mr-2" /> : <FaCopy className="mr-2" />}
              {copiedLink === ql.url ? 'Copied' : 'Copy'}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default QuickLinks;
