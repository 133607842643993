import React, { useEffect, useState } from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // Update the current year dynamically
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60 * 24); // Update once a day

    return () => clearInterval(timer);
  }, []);

  return (
    <footer className="bg-black text-white py-6" role="contentinfo">
      <div className="container mx-auto px-4">
        {/* First Row: Title and Icons */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-4">
          {/* AEM-Links Title */}
          <h2 className="text-xl font-semibold mb-4 md:mb-0">
            AEM-Links
          </h2>

          {/* Social Media Icons */}
          <div className="flex space-x-4">
            {/* LinkedIn Icon */}
            <a
              href="https://www.linkedin.com/in/arunpatidar26/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn Profile"
              className="text-white hover:text-black hover:bg-white p-2 rounded transition-colors duration-300"
            >
              <FaLinkedin size={24} />
            </a>

            {/* GitHub Icon */}
            <a
              href="https://github.com/arunpatidar02/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub Profile"
              className="text-white hover:text-black hover:bg-white p-2 rounded transition-colors duration-300"
            >
              <FaGithub size={24} />
            </a>
          </div>
        </div>

        {/* Second Row: Copyright */}
        <div className="text-center text-sm">
          <p>
            Arun Patidar &copy; {currentYear}. All rights reserved | Designed and developed by Arun Patidar
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
