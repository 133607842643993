import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import GoTopButton from './components/GoTopButton';

function App() {
  useEffect(() => {
    // Initialize Google Analytics with your Tracking ID
    ReactGA.initialize('G-4XJ7QN74F0');  // Replace with your actual tracking ID

    // Optionally, you can send a pageview for the initial load
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-6">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <GoTopButton />
      <Footer />
    </div>
  );
}


export default App;
