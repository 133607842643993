import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Import the arrow-up icon from react-icons

function GoTopButton() {
  const [visible, setVisible] = useState(false);

  // Show button when scrolling down
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-4 right-4 p-3 rounded-full transition-opacity duration-300 ${
        visible ? 'opacity-100' : 'opacity-0'
      } bg-gray-100 border border-gray-600 text-gray-600 hover:bg-black hover:text-white`}
    >
      <FaArrowUp className="h-6 w-6" /> 
    </button>
  );
}

export default GoTopButton;
