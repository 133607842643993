import { FiX, FiSearch } from 'react-icons/fi'; // Icons for magnifying glass and clear


function SearchBar({ searchTerm, setSearchTerm }) {

    const handleClearSearch = () => {
      setSearchTerm('');
    };


  return (
    <div className="relative w-full md:w-2/3 lg:w-1/2 mx-auto mb-6">
      {/* Magnifying Glass Icon */}
      <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600" />

      {/* Search Input */}
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Start typing to find links..."
        aria-label="Search"
        className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400 text-gray-900"
      />

      {/* Clear Button - X */}
      {searchTerm && (
        <button
          type="button"
          onClick={handleClearSearch}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-800 focus:outline-none"
          aria-label="Clear search"
        >
          <FiX className="w-5 h-5" />
        </button>
      )}
    </div>
  );
}

export default SearchBar;
